import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';

const PlayVideo = (props) => {
    const [play, setPlayvideo] = useState(true);
    const vidRef = useRef(null);

    // Determine which video source to use
    const getChannel = () => {
        const { virtual_tour, virtual_tour2 } = props;

        if (virtual_tour && virtual_tour.type?.includes('matterport')) {
            return 'matterport';
        } else if (virtual_tour2 && virtual_tour2?.type?.includes('matterport')) {
            return 'matterport2';
        } else if (props?.videoId && props?.videoId?.includes('youtube')) {
            return 'youtubeAll';    
        } else if (props?.video_tour && props?.video_tour?.type?.includes('youtube')) {
            return 'youtube';
        } else if (props?.video_tour?.type?.includes('mp4') || props?.videoId?.includes('mp4')) {
            return 'mp4';
        } else if (props?.videoId) {
            return 'youtubeAll';
        }
        return null; 
    };

    // Get video URLs
    const getVideoUrl = () => {
        const channel = getChannel();

        switch (channel) {
            case 'matterport':
                return [props.virtual_tour?.url]; // First Matterport URL
            case 'matterport2':
                return [props.virtual_tour2?.url]; // Second Matterport URL
            case 'youtubeAll':
                return props.videoId ? `https://www.youtube-nocookie.com/embed/${props.videoId}` : '';
            case 'youtube':
                return props.video_tour?.url || '';
            case 'mp4':
                return [props?.video_tour?.url || props?.videoId];
            default:
                return []; 
        }
    };

    const videoUrls = [
        props.virtual_tour?.url,
        props.virtual_tour2?.url
    ].filter(Boolean); 

    return (
        <Modal
            show={props.isOpen}
            onHide={() => props.isCloseFunction(false)}
            dialogClassName="modal-video"
            aria-labelledby="example-custom-modal-styling-title"
            backdropClassName="video-backdrop"
            size="lg"
        >
            <Modal.Header closeButton />
            <Modal.Body>
                {videoUrls.length > 0 ? (
                    videoUrls.map((url, index) => (
                        <iframe
                            key={index}
                            title={`Matterport ${index + 1}`}
                            src={url}
                            width="100%"
                            //height="50%"
                            height={videoUrls.length === 1 ? "100%" :"50%"}
                            frameBorder="0"
                            allowFullScreen
                        />
                    ))
                ) : (
                    <ReactPlayer
                        url={getVideoUrl()}
                        playing={play}
                        muted={false}
                        controls={false}
                        playsinline
                        loop
                        width="100%"
                        height="100%"
                        ref={vidRef}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default PlayVideo;
